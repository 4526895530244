.Insights {
  width: 100%;
  height: auto;
  margin-top: 130px;
  background-color: white;
}

.Insightshead h1 {
  width: 100%;
  font-family: 'Times New Roman', Times, serif;
  color: black;
  font-size: 60px;
  /* background-color: #e6ffe6; */
  /* border-top: 1px solid black; */
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.topimage {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 450px;
  padding: 10px;
}

.topimage img {
  width: 100%;
  height: 450px;
  border-radius: 10px;
  display: block;

}

.topimage a {
  position: absolute;
  margin: auto;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  font-size: 18px;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.Insightscontent {
  display: flex;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
  min-height: 500px;
  border-radius: 10px;
  /* background-color: #e6ffe6;*/
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid black;
  border-left: none;
  /* margin-left: 200px; */

}

.leftcontent {
  display: flex;
  flex-direction: column;
  height: 665px;
  padding: 20px;
  margin-top: 0;
  flex: 17;
  padding: 20px;
  position: fixed;
  /* position: sticky; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid black;
  border-radius: 10px;
  z-index: 1000;
  margin-top: 38px;
}

.sidebarcss{
  list-style-type: none;
  padding: 0;
}

.sidebaritem{
  font-size: 1.2rem;
  text-align: left;
  margin: 30px 0;
  margin-left: 5px; 
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.2s ease;
  /* font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
}

.sidebaritem:hover{
  background-color: #848383; /* Honey-like yellow */
  transform: scale(1.1);
  color: aliceblue;
  border-radius: 10px;
}

.leftcontent h2 {
  flex-grow: 1;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  font-size: 20px;
}

.centercontent {
  flex: 55;
  /* 65% of the available space */
  /* background-color: #e6ffe6; Light blue background */
  padding: 20px;
  margin-left: 20%;
}

.Announcementbox {
  /* background-color: rgb(226, 200, 139); */
  border-radius: 10px;
  border: 1px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
}

.Announcementbox:hover {
  transform: scale(1.1);
  background-color: rgb(241, 244, 247);
}

.Announcementbox h2 {
  border-bottom: 1px solid rgb(160, 160, 160);
}

.Announcementbox p {
  text-align: right;
}

.Announcementbox img {
  width: 33%;
  height: auto;
  padding: 20px;
  float: left;
  border-radius: 10px;
}

.Healthprobox {
  margin-top: 10px;
  transition: transform 0.3s ease;
  display: flex;
  /* background-color: rgb(20, 27, 27); */
}

.Healthbox {
  flex: 50;
  /* background-color: rgb(162, 162, 226); */
  padding: 2.5px;
  margin-right: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid black;
}

.Healthbox:hover {
  transform: scale(1.2);
  background-color: aliceblue;
}

.Healthbox h2 {
  text-align: center;
  border-bottom: 1px solid rgb(160, 160, 160);
}

.Healthbox img {
  width: 40%;
  height: auto;
  margin: 10px;
  float: left;
  border-radius: 10px;
}

.Productbox {
  flex: 50;
  transition: transform 0.3s ease;
  margin-left: 10px;
  border-radius: 10px;
  border: 1px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  /* background-color: rgb(228, 180, 187); */
}

.Productbox:hover {
  transform: scale(1.2);
  background-color: aliceblue;
}

.Productbox img {
  width: 40%;
  height: auto;
  margin: 10px;
  float: left;
  border-radius: 10px;
  /* background-color: beige; */
}

.Productbox h2 {
  text-align: center;
  border-bottom: 1px solid rgb(160, 160, 160);
}

.Globlebox {
  margin-top: 10px;
  transition: transform 0.3s ease;
  /* background-color: rgb(226, 200, 139); */
  border-radius: 10px;
  border: 1px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.Globlebox:hover {
  transform: scale(1.2);
  background-color: aliceblue;
}

.Globlebox h2 {
  border-bottom: 1px solid rgb(160, 160, 160);
}

.Globlebox h3 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  margin: 5px;
}

.Globlebox p {
  text-align: right;
}

.Globlebox img {
  width: 40%;
  height: auto;
  padding: 20px;
  float: left;
  border-radius: 10px;
}

.Articlebox {
  margin-top: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  border: 1px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.Articlebox h2 {
  border-bottom: 1px solid rgb(160, 160, 160);
}

.Articlebox img {
  width: 100%;
  height: auto;
  padding: 20px;
  float: left;
  border-radius: 10px;
}

.Articlebox h3 {
  margin-top: 200px;
  font-size: 20px;
  /* padding: 7px; */
}

.articletext {
  padding: 20px;
  text-align: left;
}

.articleptext {
  /* margin-top: 200px; */
  text-align: left;
}

.articletext ul {
  list-style-type: disc;
  padding-left: 20px;
}

.postbox {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

/* .postbox:hover{
  transform: scale(1.2);
} */

.postbox img {
  width: 100%;
  height: auto;
  padding: 20px;
  float: left;
}

.postbox h2 {
  border-bottom: 1px solid rgb(160, 160, 160);
}

.postbox h3 {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  border: 1px solid black;
}

.centercontent h2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 30px;
}

.centercontent p {
  padding: 20px;
  text-align: center;
}

.rightcontent {
  flex: 23;
  /* 35% of the available space */
  /* background-color: #f0f8ff; Light green background */
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
  border-radius: 10px;
}

.newsproduct {
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
}

.rightcontent h2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 25px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgb(160, 160, 160);
}

.newsproduct img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: auto;
}

.rightprobuybtn {
  text-shadow: 1px;
  text-align: center;
  width: 90%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  font-family: 'Times New Roman', Times, serif;
  transition: opacity(0.3s);
}

.rightprobuybtn :hover {
  font-size: 20px;
  transform: scale(1.1);
  background-color: rgb(126, 127, 129);
}