.homepg {
    width: 100vw;
    height: 600px;
    display: flex;
    align-items: center;
    background-color: #ffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 255, 255);
}

.head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.animatelogo{
    margin-left: auto;
    margin-right: auto;
}

.animatelogo img{
    float: left;
    width: 300px;
    height: auto;
    align-items: left;
    justify-content: center;
}

.head h1 {
    align-items: center;
    font-size: 130px;
    font-family: 'Times New Roman', Times, serif;
    color: rgb(31, 26, 17);
    display: inline;
    margin: 0;
}

.slogan h2{
    font-family: 'Times New Roman';
    color: rgb(31, 26, 17);
    font-size: 150%;
}

@media screen and (max-width: 600px) {
    .homepg {
        width: 100vw;
        height: auto;
        padding-top:80px;
        display: flex;
        flex-direction: column-reverse;
    }

    .homepg img{
        width: 200px;
    }
    
    .head h1{
        font-size: 40px;
        margin-top: 20px;
    }

    .slogan h2{
        margin-top: -20px;
        font-size: 15px;
        letter-spacing: 3px;
    }

    .head-top{
        background-color: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
} 

