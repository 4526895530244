.ourProducts{
    width: 100vw;
    margin: auto;
}

.products {
    width: 100vw;
    background-color: #ffff;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    text-align: center;
}

.productsSlider img{
    width: 80%;
    height: auto;
    border-radius: 10px;
    margin: 100px auto;
}

.header h1 {
    display: block;
    font-family: 'Times New Roman';
    font-size: 3.2rem;
    text-align: center;
    color: rgb(0, 0, 0);
    padding: 20px;
    margin-top: 10px;
}

.products h2 {
    font-family: 'Times New Roman';
    text-align: center;
    padding: 10px;
    margin-left: 0;
    font-weight: bold;
    font-size: 1.9em;
    height: auto;
}

.productrow {
    flex: wrap;
    background-color: none;
    margin: 25px;
    /* padding: 10px; */
    width: 100%;
    /* height: 900px; */
    height: auto;
    position: relative;
    transition: filter 0.3s ease-in-out; 
    animation: change 3s infinite ease-in-out;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.para {
    width: 78%;
    height: auto;
    font-size: bold;
    color: #666;
    font-size: 1.3em;
    margin: auto;
    padding: 30px;
    font-family: 'Times New Roman';
    text-align: left;
    justify-content: center;
    /* border :1px solid black; */
    align-items: center;
    justify-content: center;
}

.details {
    width: 400px;
    height: auto;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
}

/* .img-wrap:hover .details{
    opacity: 1;
} */

.products h2:hover {
    cursor: pointer;
    color: rgb(104, 74, 29);
}

.product-box img {
    transition: transform 0.3s ease-in-out;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
}


.imgWrap {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: auto;
    /* gap: 20px; */
}

.hover-text {
    position: relative;
    top: 0;
    left: 50;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.productBox {
    width: 410px;
    height: auto;
    border: 1px solid black;
    background-color: rgb(246, 246, 246);
    margin: 20px;
    padding: 10px;
    border-radius: 9px;
    overflow: hidden;
}

.productTitle{
    color: #333;
    font-size: 18px;
    margin-bottom: 8px;
    font-family: 'Times New Roman';
    border: 1px solid black;
    color: rgb(0, 0, 0);
    padding: 12px;
    margin: auto;
    transition-duration: 0.4s;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
    width: 95%;
    border-radius: 10px;
}

.product-title:hover {
    cursor: pointer;
    background-color: rgb(172, 172, 172);
}

.imgContainer {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    /* border: 1px solid black; */
    border-radius: 10px;
}

.imgContainer img {
    overflow: hidden;;
    width: 100%;
    border-radius: 10px;
}

.product-box img:hover {
    transform: scale(1.1);
}


.addcard-btn {
    border: 1px solid black;
    color: rgb(0, 0, 0);
    padding: 12px;
    margin: auto;
    transition-duration: 0.4s;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
    width: 95%;
    border-radius: 10px;
}

.addcard-btn:hover {
    cursor: pointer;
    background-color: rgb(172, 172, 172);
}

.overlay p {
    text-align: left;
    padding: 15px;
}

.overlay2 p {
    text-align: left;
    padding: 15px;
}

.overlay3 p {
    text-align: left;
    padding: 15px;
}

.overlay:hover {
    opacity: 1;
}

.overlay2:hover {
    opacity: 1;
}

.overlay3:hover {
    opacity: 1;
} 

.readMore{ 
    border: 1px solid black;
    border-radius: 10px;
    margin: auto;
    padding: 5px;
    width: 370px;
    margin-top: 10px;
}

.readMore:hover {
    cursor: pointer;
    background-color: rgb(172, 172, 172);
}

.readmoreContent {
    display: none;
    position: absolute;
    top: 100%;
    /* left: 0; */
    background-color: #000000;
    color: white;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    padding: 10px;
    z-index: 1000;
}

.readmoreContent a {
    font-family: 'Times New Roman';
    text-decoration: none;
}

.hoverReadmore:hover+.readmoreContent {
    display: block;
}

.hoverReadmore{
    cursor: pointer;
}

.products-slider{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 160px;
}

.products-slider img{
    border-radius: 10px;
}

@media screen and (max-width: 600px) {

    .header h1{
        margin-top: -50px;
    }
    .para {
        text-align: justify;
        font-size: 15px;
        width: 100%;
    }

    .products {
        width: 100vw;
        margin-left: 0px;
        background-color: #ffff;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    }

    .productsContainer{
        margin-left: -50px;
    }

    .productsSlider img{
        width: 80%;
        height: auto;
        border-radius: 10px;
    }

    .productBox{
        width: 280px;
        height: auto;
        margin: auto;
        margin-bottom: 10px;
    }

    .imgContainer{
        width: 250px;
        height: 250px;
        margin: auto;
    }

    .productTitle{
        width: 250px;
        margin-top: 5px;
    }

    .productrow h2{
        font-size: large;
    }

    .imgWrap {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
    }

    .overlay {
        display: none;
    }

    .overlay2 {
        display: none;
    }

    .overlay3 {
        display: none;
    }

    .readMore{
        font-size: 15px;
        width: 250px;
    }
}