.slider{
    width: 100%;
    margin: auto;
    height: auto;
}

.slidershow{
    display: flex;
    width: 100%;
    height: 600px;
    justify-content: center;

}

.img-slider img{
    width: 100%;
    border-radius: 10px;
    height: 600px;
}

.pro-discription {
    width: 100%;
    height: auto;
    margin: auto;
    background-color: white;
}

.pro-discription img{
    width: 100%;
    height: auto;
    display: flex;
}

@media screen and (max-width: 600px){
    .slider{
        height: auto;
        width: auto ;
    }
    .img-slider{
        width: auto;
        height: auto;
    }
}

/* .slider{
    background-color:none;
    width: 100%;
    height: 600px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.slide-image{
    width: 90%;
    height: 600px;
    margin: auto;
    border-radius: 10px;
} */