.cr{
    width: 100vw;
    height: auto;
    background-color: #ffff;
    margin: 20px 20px 20px 0px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: filter 0.3s ease-in-out;
    animation: change 3s infinite ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cr-heading{
    font-family: 'Times New Roman', Times, serif;
}

.cr-heading h1{
    font-size: bold;
    padding: 10px;
    margin: 10px;
    font-size: 180%;
}

.cr-para{
    width: 78%;
    text-align: center;
    padding: 20px;
    margin:auto;
    font-family: 'Times New Roman';
    font-size: 150%;

}

.certi-img{
    display: flex;
    flex-direction: row;
}

.certi-img img{
    transition: transform 0.3s ease-in-out;
    display: flex;
    float: left;
}

.certi-img img{
    width: 250px;
    height: auto;
    padding: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}


.certi-img img:hover{
    transform: scale(1.1);
    cursor: pointer;
    filter: grayscale(0%)
}

.mission{
    width: 100%;
    height: 600px;
    margin: auto;
}

.our-mission img{
    width: 100%;
    margin: auto;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width: 600px){
    .certi-img{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .cr-para{
        width: 100vw;
        text-align: justify;
    }
    
    .certi-img-box{
        width:50%
    }
} 