.BestSellerContainer{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
}

.BestSellerContainer{
    font-size: 40px;
    font-family: 'Times New Roman';
}

.bestSellerBox{
    display: flex;
    flex-wrap: wrap;
    margin: 40px;
}

.subBox{
    width: 22%;
    margin: 20px;
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
}

.subBox button{
    font-size: medium;
    text-shadow: 1px;
    text-align: center;
    width: 90%;
    border: 1px solid black ;
    border-radius: 10px;
    margin: 10px;
    font-family: 'Times New Roman', Times, serif;
    transition: opacity(0.3s);
}

.subBox button :hover{
    font-size: 20px;
    transform: scale(1.1);
    background-color: rgb(126, 127, 129);
}

@media  screen and (max-width: 600px) { 
    .BestSellerContainer{
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    
    .BestSellerContainer{
        font-size: 40px;
        font-family: 'Times New Roman';
    }
    
    .bestSellerBox{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 20px;
    }
    
    .subBox{
        width: 40%;
        margin: 10px;
        border: 1px solid black;
        border-radius: 10px;
        overflow: hidden;
    }
    
    .subBox button{
        font-size: medium;
        text-shadow: 1px;
        text-align: center;
        width: 90%;
        border: 1px solid black ;
        border-radius: 10px;
        margin: 10px;
        font-family: 'Times New Roman', Times, serif;
        transition: opacity(0.3s);
    }
    
    .subBox button :hover{
        font-size: 20px;
        transform: scale(1.1);
        background-color: rgb(126, 127, 129);
    }
}

