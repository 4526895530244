.App {
  text-align: center;
  background-color: #ffffff;
  width: 100vw;
  height: auto;
  margin: 0;
  padding: O;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.home-header{
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
}

.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.6s ease-out, transform 1.6s ease-out;
  padding: 10px;
  margin: 20px 0;
  /* background-color: #f4f4f4;
  border: 1px solid #ddd; */
}

/* When the section is in view */
.section.in-view {
  opacity: 1;
  transform: translateY(0);
}


@media screen and (max-width: 500px) {
  .App{
    width: 100vw;
    max-width: none;
    height: auto;
  }
}
