@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.pro-slider{
    background-color: rgb(255, 255, 255);
    width: 100vw;
    height: 500px;
    margin: 0 auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.product-img-slider{
    justify-content: center;
    margin-top: 180px;
    /* align-items: center; */
    width: 100%;
    height: 500px;
    display: flex;
}

.product-img-slider img {
    border-radius: 10px;
}

.Retail-boxs{
    /* display: flex; */
    justify-content: center;
    /* align-items: center; */
    margin: auto;
    width: 100%;
}

.all-prodcuts{
    display: flex;
    width: 100%;
    justify-content: center;
}

.all-pro{
    float: left;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.boxes{
    display: flex;
    float: left;
    margin-right: 30px;
    margin: auto ;
    justify-content: center;
    align-items: center;
}

.sub-title{
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
    padding: 24px;
}

.sub-box{
    max-width: 350px;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid black ;
    margin: auto;
    margin-right: 40px;
    /* padding: 10px; */
}

.sub-box img{
    border-radius: 10px;
}
.sub-box button{
    text-shadow: 1px;
    text-align: center;
    width: 90%;
    border: 1px solid black ;
    border-radius: 10px;
    margin: 10px;
    font-family: 'Times New Roman', Times, serif;
    transition: opacity(0.3s);
    /* font-size: 16px; */
}

.sub-box button:hover{
    font-size: 20px;
    transform: scale(1.1);
    background-color: rgb(126, 127, 129);
}

.reviews{
    width: 100%;
    display: flex;
    justify-content: center;
}

.reviews h1{
    margin-top: 20px;
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif;
}

.rev h2{
    font-family: 'Times New Roman', Times, serif;
    font-size: larger;
    margin: 19px;
    border-bottom: 1px solid black;
}

.rev p{
    margin: 10px;
}

.reviews-box{
    display: flex;
    float: left;
    width: 100%;
    height: 500px;
    border: 1px solid black ;
}

.rev{
    float: left;
    width: 400px;
    height: auto;
    border-radius: 10px;
    border: 1px solid black ;
    margin: 20px;
}

@media screen and (max-width: 600px) {
    .pro-slider{
        width: 100%;
        height: auto;
        /* margin-top: 10px; */
    }

    .product-img-slider{
        width: 100vw;
        margin-top: 100px;
        height: auto;
    }

    .sub-title{
        font-size: medium;
    }

    .all-prodcuts{
        display: flex;
        width: 100vw;
    }

    .all-pro{
        width: 100vw;
        height: auto;
    }

    .all-pro h2{
        font-size: 30px;
    }

    .boxes{
        display: flex;
        flex-wrap:wrap;
    }

    .sub-box{
        /* display: flex; */
        margin: 20px;
        height: auto;
    }

    .sub-box a{
        font-size: small;
    }
}